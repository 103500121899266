import React from "react";
import cookie from "react-cookies";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import Link from "../../../../../core/Components/Link";

function getMinutes(minutes) {
  return new Date(new Date().getTime() + minutes * 60 * 1000);
}

const LoginLink = ({
  isActiveDropdownNav,
  showPopOver,
  isProfileEnabled,
  isLoginEnabled,
  loginPath,
  isUserLoggedIn,
  callbackClassDropdownNav,
  callbackWhatsNew,
  callbackWhatsNewContent,
  callbackShowPopOver,
  loginLink,
  cookieDomain,
  authUHDropdownMenu,
  authRevoke,
  authAuthorize,
  authLogout,
  drxMain,
  authorizeApp,
  authorizeAppAuthorizePath,
  storeLogout,
  cochlearMCR,
  urlLang,
  showProfileLogo,
  showGivenNameLast,
}) => {
  if (isProfileEnabled) {
    if (isUserLoggedIn) {
      return (
        <ProfileDropdown
          urlLang={urlLang}
          cookieDomain={cookieDomain}
          authUHDropdownMenu={authUHDropdownMenu}
          authRevoke={authRevoke}
          authAuthorize={authAuthorize}
          authLogout={authLogout}
          drxMain={drxMain}
          authorizeApp={authorizeApp}
          authorizeAppAuthorizePath={authorizeAppAuthorizePath}
          storeLogout={storeLogout}
          cochlearMCR={cochlearMCR}
          isActiveDropdownNav={isActiveDropdownNav}
          showPopOver={showPopOver}
          callbackClassDropdownNav={callbackClassDropdownNav}
          callbackWhatsNew={callbackWhatsNew}
          callbackWhatsNewContent={callbackWhatsNewContent}
          callbackShowPopOver={callbackShowPopOver}
          showProfileLogo={showProfileLogo}
          showGivenNameLast={showGivenNameLast}
        />
      );
    } else {
      return (
        <a
          onClick={() => {
            // Set referrerUrl as a cookie as it needs to be shared across sub domains
            return cookie.save("referrerUrl", window.location.href, {
              path: "/",
              domain: cookieDomain,
              secure: true,
              expires: getMinutes(15),
            });
          }}
          href={loginPath}
          className="login"
        >
          <span>{loginLink.value.text}</span>
        </a>
      );
    }
  } else if (isLoginEnabled) {
    return <Link field={loginLink} className="login" />;
  } else {
    return "";
  }
};

export default LoginLink;
